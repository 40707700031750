// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("./channels")
// require('datatables.net-bs4')
window.jQuery = require('jquery')
window.$ = window.jQuery
import select2 from "select2"

window.select2 = select2();

import Splide from '@splidejs/splide'

window.Splide = Splide;


import sweetAlert from 'sweetalert2'

window.sweetAlert = sweetAlert;
import Swal from 'sweetalert2'

window.Swal = Swal;

require('./bootstrap.bundle.min')
require("bootstrap-datepicker")
require("daterangepicker")
require("local-time")
require('./jquery.easing.min')
require('./new-age')
require("./bootstrap-datepicker.es")
require('./custom2')
require('./imageuploadify')
require("chartkick")
require("chart.js/dist/chart")
require("./slick.min")
require("clockpicker/src/clockpicker")
require('./landing/custom.js'); // Import your custom JavaScript file


// https://datatables.net/download/index
import jszip from 'jszip';
import pdfmake from 'pdfmake';

require('./landing/owl.carousel.min'); // Import Owl Carousel
import dataTable from 'datatables.net-bs4'

dataTable(window, $)
require('datatables.net-autofill-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-colreorder-bs4');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-fixedheader-bs4');
require('datatables.net-keytable-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('datatables.net-rowreorder-bs4');
require('datatables.net-scroller-bs4');
require('datatables.net-searchbuilder-bs4');
require('datatables.net-searchpanes-bs4');
require('datatables.net-select-bs4');
require('datatables.net-staterestore-bs4');

import {DatatableBase} from '@jbox-web/datatables-factory'

// Close context_menu when click outside
$(document).on('click', DatatableBase.clean_context_menu)

// Load datatables
$(document).on('turbo:load', DatatableBase.load_datatables)


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$.extend($.fn.dataTable.defaults, {
    processing: true,
    serverSide: false,
    stateSave: true,
    responsive: true,
    pageLength: 10,
    lengthMenu: [[10, 25, 50, 100, 200, 500], [10, 25, 50, 100, 200, 500]],
    pagingType: 'simple_numbers',
    dom: 'lfrtip',
    order: [],
    language: {
        "decimal": "",
        "emptyTable": "No hay datos disponibles en la tabla",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ resultados",
        "infoEmpty": "Mostrando 0 a 0 de 0 resultados",
        "infoFiltered": "(filtrado de _MAX_ resultador)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_ resultados",
        "loadingRecords": "Cargando...",
        "processing": "",
        "search": "Buscar:",
        "zeroRecords": "No se encontraron resultados",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
        },
        "aria": {
            "sortAscending": ": activar para order la columna ascendentemente",
            "sortDescending": ": activar para order la columna descendentemente"
        }
    }
});

window.sweetAlert = function ({message, icon, showCancel = true, showConfirm = true}) {
    return Swal.fire({
        text: message,
        icon: icon,
        showCancelButton: showCancel,
        showConfirmButton: showConfirm,
        cancelButtonText: 'Cancelar',
    });
}
// preloader
window.switchScreen = function () {
    lockScreen();
    setTimeout(function () {
        unlockScreen();
    }, 2000);
}

window.lockScreen = function () {
    $('#preloader').show();
    $('#page-top').addClass('lock-screen');
}

window.unlockScreen = function () {
    $('#preloader').hide();
    $('#page-top').removeClass('lock-screen');
}

$(document).ajaxSend(function (event, xhr, options) {
    if (!options.url.includes('/visitors/toggle_helper'))
        lockScreen();
}).ajaxComplete(function (event, xhr, options) {
    unlockScreen();
});
$(document).on('turbolinks:load', function () {
    $('#nav-icon1').click(function () {
        $(this).toggleClass('open');
        $(".sidebar-container").toggleClass('open');
        $(".sidebar-container-overlay").toggleClass('open');

    });
    $('#page-top input[type="submit"]').click(function () {
        let a = 0
        $(':input[required]:visible').each(function () {
            if ($(this).val() === '') a++;
        })
        $('select[required]:visible').each(function () {
            if ($(this).val().length === 0) a++;
        })
        if (a === 0) {
            switchScreen();
        }

    })
    // $.rails.refreshCSRFTokens();
});


// sections.js
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

require('@ckeditor/ckeditor5-build-classic/build/translations/es');
document.addEventListener('DOMContentLoaded', () => {
    let privacy_editor = document.querySelector('#privacy_policy_content')
    if (document.body.contains(privacy_editor))
        ClassicEditor
            .create(privacy_editor, {
                language: 'es'
            })
            .then(editor => {
                console.log(Array.from(editor.ui.componentFactory.names()));
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new Adapter(loader);
                };
            })
            .catch(error => {
                console.error(error);
            });
});

class Adapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return new Promise((resolve, reject) => {
            const reader = this.reader = new window.FileReader();
            reader.addEventListener('load', () => {
                resolve({default: reader.result});
            });
            reader.addEventListener('error', err => {
                reject(err);
            });
            reader.addEventListener('abort', () => {
                reject();
            });
            this.loader.file.then(file => {
                reader.readAsDataURL(file);
            });
        });
    }

    abort() {
        this.reader.abort();
    }
}

// terms-of-use
document.addEventListener('DOMContentLoaded', () => {
    let terms_editor = document.querySelector('#admin_term_of_use_body')
    if (document.body.contains(terms_editor))
        ClassicEditor
            .create(terms_editor, {
                language: 'es'
            })
            .then(editor => {
                console.log(Array.from(editor.ui.componentFactory.names()));
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new Adapter(loader);
                };
            })
            .catch(error => {
                console.error(error);
            });
});