$(document).on('turbolinks:load',function() {
    "use strict"; // Start of use strict

    // Smooth scrolling using jQuery easing
    $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 48)
                }, 1000, "easeInOutExpo");
                return false;
            }
        }
    });

    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function() {
        $('.navbar-collapse').collapse('hide');
    });

    // Activate scrollspy to add active class to navbar items on scroll
    $('body').scrollspy({
        target: '#stickyHeader',
        offset: 54
    });

    // Collapse Navbar
    var navbarCollapse = function() {
        if ($("#stickyHeader").offset().top > 100) {
            $("#stickyHeader").addClass("navbar-shrink");
        } else {
            $("#stickyHeader").removeClass("navbar-shrink");
        }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);
    $('[data-toggle="tooltip"]').tooltip()
}); // End of use strict

// image popup
/*jQuery(function($){
    $('#img_open_form').click(function(){
        var img = $(this).attr("src");
        var appear_image = "<div id='appear_image_div' onclick='closeImage()'></div>";
        appear_image = appear_image.concat("<img id='appear_image' src='"+img+"' />");
        $('body').append(appear_image);
    });
    function closeImage(){
        $('#appear_image_div').remove();
        $('#appear_image').remove();
    }
});*/

