$(document).on('turbolinks:load', function () {

    $(document).on("click", ".gogocar-arrow-button", function () {
        $(this).toggleClass('active');
        $(this).parents('.section-bottom-side--mobile__item').next().slideToggle(200).css('display', 'flex');
    });

    $('#user_password').keyup(function () {
        var pswd = $(this).val();
        if (pswd.length < 8) {
            $('#length').removeClass('valid').addClass('invalid');
        } else {
            $('#length').removeClass('invalid').addClass('valid');
        }
        //validate letter
        if (pswd.match(/[A-z]/)) {
            $('#letter').removeClass('invalid').addClass('valid');
        } else {
            $('#letter').removeClass('valid').addClass('invalid');
        }

        //validate capital letter
        if (pswd.match(/[A-Z]/)) {
            $('#capital').removeClass('invalid').addClass('valid');
        } else {
            $('#capital').removeClass('valid').addClass('invalid');
        }

        //validate number
        if (pswd.match(/\d/)) {
            $('#number').removeClass('invalid').addClass('valid');
        } else {
            $('#number').removeClass('valid').addClass('invalid');
        }
    }).focus(function () {
        $('#pswd_info').show();
    }).blur(function () {
        $('#pswd_info').hide();
    });

    $("#confirm_email").keyup(function () {
        if ($(this).val() != $("#user_email").val()) {
            $("#email_match").show();
        } else {
            $("#email_match").hide();
        }
    })

    $("#user_password_confirmation").keyup(function () {
        if ($(this).val() != $("#user_password").val()) {
            $("#pass_match").show();
        } else {
            $("#pass_match").hide();
        }
    })

    $('.datepicker').datepicker({
        format: 'dd-mm-yyyy',
        language: "es"
    }).on("show", function () {
        $(".datepicker-days table").removeClass("table-condensed").addClass("table");
    });
    $(".report_date").daterangepicker({
        locale: {
            format: "DD-MM-YYYY",
            "daysOfWeek": ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            "monthNames": [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre"
            ]
        },
        autoUpdateInput: false
    });
    $('.report_date').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
        $.ajax({
            url: '/parent/reports/get_report',
            data: {
                date: {
                    first_date: picker.startDate.format('DD-MM-YYYY'),
                    second_date: picker.endDate.format('DD-MM-YYYY')
                },
                child_id: $("#child_id").val()
            }
        })
    });
    $(".admin_report_date").daterangepicker({
        locale: {
            format: "DD-MM-YYYY",
            "daysOfWeek": ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            "monthNames": [
                "Enero",
                "Febrero",
                "Marzo",
                "Abril",
                "Mayo",
                "Junio",
                "Julio",
                "Agosto",
                "Septiembre",
                "Octubre",
                "Noviembre",
                "Diciembre"
            ]
        },
        autoUpdateInput: false
    });
    $('.admin_report_date').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
        $("#form-date").val($(this).val())
    });
    // $("#user_rut").keyup(function () {
    //     if($(this).val().length == 2 || $(this).val().length == 6) {
    //         $(this).val($(this).val() + ".");
    //     } else if ($(this).val().length == 11) {
    //         $(this).val($(this).val() + "-");
    //     } else if ($(this).val().length == 13){
    //         return
    //     }
    // })

    $("#child_dob").change(function () {
        var date = $(this).val().split("/")
        dob = new Date(date[2], date[1] - 1, date[0]);
        var today = new Date();
        var age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));

        $('#child_age').html(age);
        $('#child_age').val(age);
    })

    $(".delete-modal").click(function () {
        var id = $(this).data('id');
        $.ajax({
            url: "/parent/children/" + id + "/delete_child",
            success: function (result) {
                $("#exampleModal").modal('show');
            }
        })
    })

    // register form validations start

    $("#user_email, #confirm_email, #user_password, #user_password_confirmation, #user_name,#user_last_name,#user_rut,#user_rut,#user_bod,#user_address,#user_city,#user_country").on('blur', function () {
        if ($(this).val() === "") {
            $(this).addClass("invalid-input");
        } else {
            $(this).addClass("valid-input");
        }
    });

    var validate_phone = $("#user_phone_number, .validate_phone");

    validate_phone.blur(function () {
        if ($(this).val() === "") {
            $(this).addClass("invalid-input");
        } else {
            if ($("#phone_errors").is(":visible")) {

            } else {
                $(this).addClass("valid-input");
            }
        }
    });

    validate_phone.keyup(function () {
        if ($(this).val().length === 1) {
            if ($(this).val() !== "+") {
                $("#phone_errors").html("<p style='color: red;'>Formato incorrecto. Comience con '+'.</p>").show()
            } else {
                $("#phone_errors").hide()
            }
        } else if ($(this).val().length === 4) {
            if ($(this).val() !== "+569") {
                $("#phone_errors").html("<p style='color: red;'>Formato incorrecto. Ingrese el código de país '569'.</p>").show()
            } else {
                $("#phone_errors").hide()
            }
        } else if ($(this).val().length > 4) {
            if ($(this).val().substring(0, 4) !== "+569") {
                $("#phone_errors").html("<p style='color: red;'>Formato incorrecto. Comience con el código de país '+569'.</p>").show()
                $(this).addClass("invalid-input");
            } else {
                $("#phone_errors").hide();
                $(this).addClass("valid-input");
            }
        }
    })
    // register form validations end

    // add children form validations
    $("#child_user_id,#child_name, #child_last_name, #child_dob, #child_school_name, #child_city,#child_country,#child_rut").on('blur', function () {
        if ($(this).val() == "") {
            $(this).addClass("invalid-input");
        } else {
            $(this).addClass("valid-input");
        }
    })
    // add children form validations end

    // session search start
    // $("#session_search").select2({
    //     ajax: {
    //         url: '/calendar/search',
    //         dataType: 'json',
    //         data: function (params) {
    //             var query = {
    //                 search: params.term
    //             }
    //             return query;
    //         },
    //         processResults: function (data) {
    //             return {
    //                 results: $.map(data.items, function (obj) {
    //                     return {
    //                         id: obj.id,
    //                         text: obj.name + " " + obj.last_name
    //                     };
    //                 })
    //             }
    //         },
    //         cache: true,
    //     },
    //     minimumInputLength: 3,
    //     allowClear: true,
    //     placeholder: "escriba un nombre",
    //     escapeMarkup: function(markup){return markup;}
    //   });

    $(".session_search").change(function () {
        if ($(this).val() !== "") {
            $(".calendar_ajax").hide()
            $.ajax({
                url: '/common/calendar/get_child',
                data: {
                    id: $(this).val()
                }
            });
        }
    });

    $(document).on("click", ".child-selected", function () {
        $("#users-search").val($(this).attr("child-id")).change();
    })
    // session search end

    //   custom script

    var ss2 = function () {
        if ($(window).width() > 768) {
            $(document).on('click', '.section-tbody--show__popup', function () {
                $(this).children('.section-tbody--modal--table,.section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeToggle(200);
                $(this).siblings().find('.section-tbody--modal--table, .section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeOut(200);
                $(this).parent().prevAll().children().children('.section-tbody--modal--table, .section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeOut(200);
                $(this).parent().nextAll().children().children('.section-tbody--modal--table,.section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeOut(200);
            });
        }
            $(document).on('click', '.section-tbody--show__popup_mobile', function () {
                $(this).children('.section-tbody--modal--table,.section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeToggle(200);
                $(this).siblings().find('.section-tbody--modal--table, .section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeOut(200);
                $(this).parent().prevAll().children().children('.section-tbody--modal--table, .section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeOut(200);
                $(this).parent().nextAll().children().children('.section-tbody--modal--table,.section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile').fadeOut(200);
            });
    };

    ss2();

    var ss = function () {
        if ($(window).width() < 768) {
            $('.section-tbody--show__popup').on('click', function () {
                $(this).parent().addClass('active').siblings().removeClass('active');
            });
        }
    };
    ss();

    $(window).resize(ss);

    $('.section-tbody--show__popup').click(function () {
        $('.section-tbody--modal--table,.section-tbody--modal--table-seccion,.left-and-right-side').addClass('active');
        $(this).children('.section-tbody--modal--table__mobile').addClass('active');
    });

    // $('.section-arrow-mobile-table').click(function () {
    //   $(this).toggleClass('active');
    //   $(this).parents('.section-bottom-side--mobile__item').next().slideToggle(200).css('display', 'flex');
    // });

    $('.mobile-close-editor').click(function (e) {
        e.stopImmediatePropagation();
        $('.section-tbody--modal--table__mobile').removeClass('active');
    });

    (function ($) {
        $.fn.swipeDetector = function (options) {
            // States: 0 - no swipe, 1 - swipe started, 2 - swipe released
            var swipeState = 0;
            // Coordinates when swipe started
            var startX = 0;
            var startY = 0;
            // Distance of swipe
            var pixelOffsetX = 0;
            var pixelOffsetY = 0;
            // Target element which should detect swipes.
            var swipeTarget = this;
            var defaultSettings = {
                // Amount of pixels, when swipe don't count.
                swipeThreshold: 50,
                // Flag that indicates that plugin should react only on touch events.
                // Not on mouse events too.
                useOnlyTouch: true
            };

            // Initializer
            (function init() {
                options = $.extend(defaultSettings, options);
                // Support touch and mouse as well.
                swipeTarget.on("mousedown touchmove", swipeStart);
                $("html").on("mouseup touchmove", swipeEnd);
                $("html").on("mousemove touchmove", swiping);
            })();

            function swipeStart(event) {
                if (options.useOnlyTouch && !event.originalEvent.touches) return;

                if (event.originalEvent.touches) event = event.originalEvent.touches[0];

                if (swipeState === 0) {
                    swipeState = 1;
                    startX = event.clientX;
                    startY = event.clientY;
                }
            }

            function swipeEnd(event) {
                if (swipeState === 2) {
                    swipeState = 0;

                    if (
                        Math.abs(pixelOffsetX) > Math.abs(pixelOffsetY) &&
                        Math.abs(pixelOffsetX) > options.swipeThreshold
                    ) {
                        // Horizontal Swipe
                        if (pixelOffsetX < 0) {
                            swipeTarget.trigger($.Event("swipeLeft.sd"));
                            //console.log("Left");
                        } else {
                            swipeTarget.trigger($.Event("swipeRight.sd"));
                            //console.log("Right");
                        }
                    } else if (Math.abs(pixelOffsetY) > options.swipeThreshold) {
                        // Vertical swipe
                        if (pixelOffsetY < 0) {
                            swipeTarget.trigger($.Event("swipeUp.sd"));
                            //console.log("Up");
                        } else {
                            swipeTarget.trigger($.Event("swipeDown.sd"));
                            //console.log("Down");
                        }
                    }
                }
            }

            function swiping(event) {
                // If swipe don't occuring, do nothing.
                if (swipeState !== 1) return;

                if (event.originalEvent.touches) {
                    event = event.originalEvent.touches[0];
                }

                var swipeOffsetX = event.clientX - startX;
                var swipeOffsetY = event.clientY - startY;


                if (
                    Math.abs(swipeOffsetX) > options.swipeThreshold ||
                    Math.abs(swipeOffsetY) > options.swipeThreshold
                ) {
                    swipeState = 2;
                    pixelOffsetX = swipeOffsetX;
                    pixelOffsetY = swipeOffsetY;

                }
            }

            return swipeTarget; // Return element available for chaining.
        };
    })(jQuery);

    $(".left-side,.left-and-right-side,.section-upper-modal").swipeDetector().on("swipeLeft.sd", function (event) {
        if (event.type === "swipeLeft") {
            $('.left-side,.left-and-right-side').removeClass('active');
            //$('body').css('overflow-y','scroll');
        } else if (event.type === "swipeRight") {
            message.text("Swipe Right");
        } else if (event.type === "swipeUp") {
            message.text("Swipe Up");
        } else if (event.type === "swipeDown") {
            //$('.section-upper-modal,.left-and-right-side').removeClass('active');
        }
    });
    $(".left-and-right-side,.section-upper-modal,.section-upper-modal__calendar,.section-tbody--modal--table,.section-tbody--modal--table-seccion,.section-tbody--modal--table__mobile").swipeDetector().on("swipeDown.sd", function (event) {
        if (event.type === "swipeLeft") {
            // $('.left-side,.left-and-right-side').removeClass('active');
            //$('body').css('overflow-y','scroll');
        } else if (event.type === "swipeRight") {
            message.text("Swipe Right");
        } else if (event.type === "swipeUp") {
            message.text("Swipe Up");
        } else if (event.type === "swipeDown") {
            $('.section-upper-modal,.section-data-container--item,.section-upper-modal__calendar,.left-and-right-side,.section-tbody--modal--table__mobile').removeClass('active');
        }
    });


    $(".status_select").change(function () {
        $.ajax({
            url: "/admin/homeworks/change_status",
            data: {
                id: $(this).data('id'),
                status: $(this).val()
            }
        })
    })

    $(".pro_status_select").change(function () {
        $.ajax({
            url: "/professional/homeworks/change_status",
            data: {
                id: $(this).data('id'),
                status: $(this).val()
            }
        })
    })

    $(".status_payed").change(function () {
        $.ajax({
            url: "/admin/payments/change_status",
            data: {
                id: $(this).data('id'),
                status: $(this).val(),
                type: $(this).data('type')
            }
        })
    })

    // session search start
    $("#report_search").select2({
        ajax: {
            url: '/common/calendar/search',
            dataType: 'json',
            data: function (params) {
                var query = {
                    search: params.term
                }
                return query;
            },
            processResults: function (data) {
                return {
                    results: $.map(data.items, function (obj) {
                        return {
                            id: obj.id,
                            text: obj.name + " " + obj.last_name
                        };
                    })
                }
            },
            cache: true,
        },
        minimumInputLength: 3,
        allowClear: true,
        placeholder: "escriba un nombre",
        escapeMarkup: function (markup) {
            return markup;
        }
    });

    $("#report_search").change(function () {

        if ($(this).val() != "") {
            $(".report_ajax").hide()
            $.ajax({
                url: '/parent/reports/get_child',
                data: {
                    id: $(this).val()
                }
            })
        }
    })


    $("#admin_report_search").select2();
    $("#professional_search").select2();
    $("#institution_search").select2();
    $(".default-select").select2();
    $("#users-search").select2();
    // session search end
    $("#admin_report_search").change(function () {
        $("#form-child-id").val($(this).val());
    })
    $("#view_report").click(function () {
        var all_dates = false
        if ($('input[name="all_date"]:checked').length > 0) {
            all_dates = true
        }
        $.ajax({
            url: "/admin/reports/generate_report",
            data: {
                date: $(".admin_report_date").val(),
                child_id: $("#admin_report_search").val(),
                all_dates: all_dates
            }
        })
    })
    $("#view_report_professional").click(function () {
        var all_dates = false
        if ($('input[name="all_date"]:checked').length > 0) {
            all_dates = true
        }
        $.ajax({
            url: "/professional/reports/generate_report",
            data: {
                date: $(".admin_report_date").val(),
                child_id: $("#admin_report_search").val(),
                all_dates: all_dates
            }
        })
    })
    $('#certificate_child_id').select2();
    $('[data-toggle="tooltip"]').tooltip();


// input mask
    const masked = document.querySelectorAll('.masked');
    const maskInput = (evt) => {
        const input = evt.target;
        const pattern = input.dataset.pattern;
        let value;
        let startIndex = 0;
        let count = 0;
        let formatedValue = "";

        value = input.value.replace(/[^\d\w]/g, "");

        for (let i = startIndex; i < pattern.length; i++) {
            if (value[count]) {
                if (pattern[i] != "*") {
                    formatedValue += pattern[i];
                } else {
                    formatedValue += value[count];
                    count++;
                }
            }
        }
        input.value = formatedValue;
    };

    function setMaskedInputListener(elem) {
        elem.addEventListener("input", maskInput);
    }

    masked.forEach((el) => setMaskedInputListener(el));

});

// rating stars
$.fn.mdbRate = function (count) {
    let $stars;
    const myDefaultWhiteList = $.fn.tooltip.Constructor.Default.whiteList;
    myDefaultWhiteList.textarea = [];
    myDefaultWhiteList.button = [];

    var $container = $(this);

    for (var i = 0; i < 5; i++) {
        $container.append(`
 <svg xmlns="http://www.w3.org/2000/svg" data-index="${i}" width="25" fill="#555555" viewBox="0 0 24 24"><path d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"/></svg>
  `);
    }

    $stars = $container.children();

    $stars.on('click', function () {
        var index = $(this).attr('data-index');
        markStarsAsActive(index);
    });

    function markStarsAsActive(index) {
        unmarkActive();
        for (var i = 0; i <= index; i++) {
            $($stars.get(i)).addClass('yellow-star');
        }
        $('#testimonial_stars').val($('#rating .yellow-star').length);
    }

    function unmarkActive() {
        $stars.removeClass('yellow-star');
    }

    markStarsAsActive(count);
}
